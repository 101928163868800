import { render, staticRenderFns } from "./InventionMember.vue?vue&type=template&id=21ab93ae&"
import script from "./InventionMember.vue?vue&type=script&lang=js&"
export * from "./InventionMember.vue?vue&type=script&lang=js&"
import style0 from "./InventionMember.vue?vue&type=style&index=0&id=21ab93ae&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports