<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row v-if="creatable">
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.inventionSaveModal
                    variant="success"
                    @click="currentInvention = undefined"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Thêm sáng chế, giải pháp
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row :class="{'mt-2': creatable}">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage - 1) + 1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>

                    <span v-else-if="props.column.field === 'action'">
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="updatable"
                          v-b-modal.inventionSaveModal
                          @click="onEditInvention(props.row)"
                        >
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Sửa</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="inventionMemberReadable"
                          v-b-modal.researchInventionMemberModal
                          @click="currentInventionId = props.row.id"
                        >
                          <feather-icon icon="UsersIcon" />
                          <span class="align-middle ml-50">Thành viên</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="deletable"
                          @click="onDeleteInvention(props.row.id)"
                        >
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Xóa</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage - 1) + 1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <InventionSave
      :invention="currentInvention"
      :research-id="researchId"
      @succeed="onSucceed"
    />

    <InventionMember :research-invention-id="currentInventionId" />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BDropdown, BDropdownItem, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource } from '@/utils'
import { RESEARCH_INVENTION_STATUSES } from '@/const/status'
import { getUser } from '@/auth/utils'
import InventionMember from '@/views/research/invention/member/InventionMember.vue'
import InventionSave from './InventionSave.vue'

export default {
  name: 'ResearchInvention',
  directives: {
    Ripple,
  },
  components: {
    InventionMember,
    InventionSave,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
  },
  props: {
    researchId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        organizationId: null,
        researchId: null,
        currentPage: 1,
        itemsPerPage: 10,
        patentNo: null,
        applNo: null,
        name: null,
        proprietor: null,
        status: null,
        sort: null,
      },
      currentInvention: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Số bằng',
          field: 'patentNo',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập số bằng',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Số đơn',
          field: 'applNo',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập số đơn',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Chủ bằng',
          field: 'proprietor',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập chủ bằng',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: RESEARCH_INVENTION_STATUSES,
            placeholder: 'Chọn trạng thái',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      currentInventionId: null,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'invention/inventions',
      totalRows: 'invention/totalRows',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RESEARCH_INVENTION)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.RESEARCH_INVENTION)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.RESEARCH_INVENTION)
    },
    inventionMemberReadable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.RESEARCH_INVENTION_MEMBER)
    },
  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    if (this.researchId) this.updateParams({ researchId: this.researchId })
    this.isLoading = true
    try {
      await this.getDataSourcesFromStore()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getInventions: 'invention/getInventions',
      deleteInvention: 'invention/deleteInvention',
    }),
    onEditInvention(invention) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = invention
      this.currentInvention = rest
    },
    onDeleteInvention(id) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa sáng chế, giải pháp?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteInvention(id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getInventions(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getInventions(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        switch (field) {
          case 'patentNo':
            this.updateParams({ sort: `1_${type}` })
            break
          case 'applNo':
            this.updateParams({ sort: `2_${type}` })
            break
          default:
            this.updateParams({ sort: `3_${type}` })
            break
        }
      }
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      const fields = this.columns.map(column => column.field)
      fields.forEach(field => {
        if (Object.hasOwn(columnFilters, field)) {
          const data = {}
          data[field] = columnFilters[field]
          this.updateParams(data)
        }
      })
      await this.getDataSourcesFromStore()
    },
    getStatusName(id) {
      return RESEARCH_INVENTION_STATUSES.find(status => status.value === id).text
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
